import React from 'react'

export default function PawRating({ opacity = 1 }) {
  return (
    <svg opacity={opacity} width="23px" height="30px" viewBox="0 0 23 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>icon/Paw-rating-1</title>
      <g id="icon/Paw-rating-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-5" fill="#A69472">
          <path d="M23,9.51537099 C23,9.51537099 22.9366371,8 21.3858651,8 C19.8354532,8 18.4429086,9.37750525 18.1892768,10.8239434 C17.936185,12.2703815 17.7781377,14.3713938 18.7908647,14.8879103 C19.8034117,15.4042347 20.8159587,14.0271135 21.4809095,12.9936966 C22.1456804,11.9604716 23,10.6173368 23,9.51537099" id="Fill-37-Copy-2"></path>
          <path d="M18.0478172,1.5326115 C18.0478172,1.5326115 16.9381449,-0.471954945 15.76724,0.103968121 C14.596719,0.680276162 13.9020461,3.20378933 13.3536404,4.64783172 C12.8052346,6.09187412 12.6194254,9.52931867 14.8929004,9.9281531 C17.1667592,10.3269875 18.5532258,9.03366296 18.881655,6.83526144 C19.2100842,4.63685993 18.830212,2.90235366 18.0478172,1.5326115" id="Fill-39-Copy-2"></path>
          <path d="M10.4571991,1.86325064 C10.4571991,1.86325064 10.0718935,-0.156303514 8.76244828,0.00970059939 C7.45282841,0.175314574 5.26116261,1.98224301 5.05191686,3.93020667 C4.84249645,5.87778019 5.18693102,9.47095971 7.5750922,9.94322288 C9.96307872,10.415291 11.1708703,7.84056913 10.9804881,6.04085826 C10.7897566,4.24056219 10.4571991,1.86325064 10.4571991,1.86325064" id="Fill-41-Copy-2"></path>
          <path d="M3.1257421,6.40752879 C3.1257421,6.40752879 1.81516871,4.5292163 0.993515975,5.11222551 C0.171863241,5.69523472 -0.476470545,7.77524795 0.465094717,9.94977003 C1.40665998,12.1242921 3.52800329,12.363019 4.40930999,11.6179946 C5.29061668,10.8727657 5.02459225,9.06973297 4.64449977,8.30936621 C4.26420575,7.54879489 3.1257421,6.40752879 3.1257421,6.40752879" id="Fill-43-Copy-2"></path>
          <path d="M21.162773,16.7409467 C21.140569,16.7531893 21.118004,16.7663456 21.0945364,16.7802328 C21.0945364,16.7802328 18.9577182,17.9798296 17.784156,16.7305313 C16.6109549,15.4814157 14.1560601,12.4730131 10.6564945,12.066813 C6.0732659,11.5348973 3.49363153,14.2818188 3.10244415,16.6550654 C2.9067602,17.8418714 2.93040835,18.7766982 3.72379485,19.6996477 C5.10260852,21.303252 6.6509307,20.9849431 7.74398081,21.3642825 C9.57120677,21.998342 9.69702938,23.1486028 10.0672764,23.8215831 C10.8593993,25.2620129 10.928719,28.4984576 11.8685075,29.6876391 C12.404111,30.3653702 13.9504475,29.8546508 14.6355218,28.9742764 C16.5670885,26.4902978 16.5058922,24.8797499 17.5852227,22.870311 C18.1157717,21.8824937 19.4001733,20.4840908 20.0877749,19.7705454 C22.1738669,17.6050584 22.5856336,15.9599753 21.162773,16.7409467" id="Fill-45-Copy-2"></path>
        </g>
      </g>
    </svg>
  )
}
