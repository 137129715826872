import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import {
  AccommodationPagePropsType,
  lang,
  RawAccommodation,
} from "../../entities/queriesTypes";
import { useGlobalStateContext } from "../../Context/site";
import useSetGlobalState from "../../components/hooks/useSetGlobalState";
import { CurrentView } from "../../entities/templates";
import Layout from "../../components/template/Layout";
import Wrapper from "../../components/template/ContainerMax";
import Text from "../../components/atoms/Text";
import translations from "../../entities/translations";
import PortableText from "../../components/sanityBlockContents/PortableText";
import GalleryCarousel from "../../components/molecule/Glidejs/Gallery";
import {
  buildImageFocalPoint,
  formatMoney,
  getCountryPageTranslation as gCPT,
} from "../../utils";
import Image from "../../components/atoms/Image";
import PageSection from "../../components/organism/HomePageComponent";
import { Card } from "../../components/molecule/Card";
import Euro from "../../components/svg/Euro";
import Button from "../../components/atoms/Button";
import PawRating from "../../components/svg/PawRating";
import TravelBag from "../../components/svg/TravelBagIcon";
import { accommodationSubMenu } from "../../utils/subNavigation";
import { useCartContext } from "../../Context/CartContext";
import { CardProps } from "../../components/molecule/Card/interface";
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";

const Index: React.FC<AccommodationPagePropsType> = (props) => {
  const { data, pageContext } = props;
  const page = data && data.pageData;
  page.parentView = "accommodation";
  const locale = pageContext && pageContext.locale;
  const { state } = useGlobalStateContext();
  const pageHash = translations.countryMenuID;

  useSetGlobalState({
    page,
    pageContext,
    locale,
    currentView: CurrentView["specific-accommodation"],
    parentView: "subviews",
    subNavigationItem: accommodationSubMenu,
  });

  const AccommodationLinks =
    translations.globals.subMenuAccommodationTranslation;
  const buttonTransaltion = translations.globals.buttons;
  const accommodation = translations.globals.accommodation;
  const pageID = translations.globals.subMenuTranslation;
  const _accomodationTranslation = translations.CountryPages.find(
    (item) => item.menu === "Unterkunfte"
  )!;
  const [countryAccommodation, setCountryAccommodations] = useState<
    RawAccommodation[] | undefined
  >(undefined);
  const FROM =
    translations.globals.subMenuTripTranslation.Ab?.[state?.lang as lang];
  const addToTravelBag =
    translations.globals.subMenuTripTranslation["Zur Reisetasche hinzufügen"];
  const removeFRomTravelBag =
    translations.globals.subMenuTripTranslation["Aus Reisetasche entfernen"];

  useEffect(() => {
    if (page.parentCountry?.accommodation?.length) {
      const otherAcc = page.parentCountry?.accommodation.filter(
        (item) => item.id !== page.id
      );
      const _accommodation = [...otherAcc];
      if (_accommodation.length < 3) {
        setCountryAccommodations(_accommodation);
      } else {
        _accommodation.length = 3;
        setCountryAccommodations(_accommodation);
      }
    }
  }, [page.id, page.parentCountry?.accommodation]);

  const { onCartClick, isInCart } = useCartContext();

  const cardCartProps = {
    uniqueRef: page?.["id"]!,
    _className: "mx-2 sm:w-full md:w-full lg:w-full mb-4",
    imageAlt:
      (page?.accommodationImage?.alt &&
        page?.accommodationImage.alt[state?.lang as lang]) ||
      "",
    imageUrl: buildImageFocalPoint(page.accommodationImage?.image),
    title: (page?.title && page?.title[state?.lang as lang]) || "",
    ctaText: buttonTransaltion.WEITERLESEN[state?.lang as lang] || "",
    cardType: "ACCOMODATION",
    activities:
      (page?.cardTextShort && page.cardTextShort[state?.lang as lang]) || "",
    price:
      `${FROM} ${
        page?.priceCategoryFrom &&
        formatMoney(page.priceCategoryFrom, page.priceCategoryCurrency)
      }` || "",
    showEuroIcon: false,
    ctaUrl: `/${state?.lang}/${
      page?.parentCountry?.slug &&
      page?.parentCountry?.slug[state?.lang as lang]?.current
    }/${_accomodationTranslation.slug[state?.lang as lang]}/${
      page?.slug && page?.slug[state?.lang as lang]?.current
    }#${AccommodationLinks.Lage[state?.lang as lang]}`,
    location:
      (page.locationNameShort && page.locationNameShort[state?.lang as lang]) ||
      "",
    cardSize: "SMALL",
  };

  const renderPortableText = (item: any) => {
    return (
      <PortableText
        content={item?.subTitle[state?.lang as lang]}
        className=""
        template={{
          h2: (props: any) => <Text type="H2">{props.children}</Text>,
          normal: (props: any) => (
            <Text type="P" ptype="BASE" className="leading-7">
              {props.children}
            </Text>
          ),
        }}
      />
    );
  };

  const cardInCart = isInCart && isInCart(cardCartProps?.uniqueRef);

  return (
    <Layout
      seoTitle={"Specific Accommodation"}
      meta={[]}
      id={AccommodationLinks.Lage[state?.lang as lang]}
    >
      <Wrapper className="mt-10">
        <div>
          <Text type="H3" className="mb-2">
            {AccommodationLinks.Lage[state?.lang as lang]}
          </Text>

          {page?.introText?.[state?.lang as lang] && (
            <PortableTextRenderer
              body={page?.introText?.[state?.lang as lang]}
            />
          )}

          {/* {page?.locationText?.[state?.lang as lang]
          && <PortableTextRenderer body={page?.locationText?.[state?.lang as lang]} />} */}

          {page?.accommodationGallery?.pictures?.length && (
            <div className="w-10/12 my-10">
              <GalleryCarousel initClass="countryOverviewGallery">
                {page.accommodationGallery?.pictures?.map((item, i: number) => (
                  <Image
                    alt={
                      (item?.alt && item?.alt[state?.lang as lang]) as string
                    }
                    src={buildImageFocalPoint(item?.image?.asset) as string}
                    key={i}
                    className="glide__slide object-cover w-full lg:h-large"
                  />
                ))}
              </GalleryCarousel>
            </div>
          )}
        </div>

        <div
          id={AccommodationLinks.Unterkunft[state?.lang as lang]}
          className="mt-8"
        >
          <Text type="H3" className="mb-2">
            {AccommodationLinks.Unterkunft[state?.lang as lang]}
          </Text>

          {page?.accommodationText?.[state?.lang as lang] && (
            <PortableTextRenderer
              body={page?.accommodationText?.[state?.lang as lang]}
            />
          )}
        </div>

        <div
          id={AccommodationLinks.Zimmer[state?.lang as lang]}
          className="mt-8"
        >
          <Text type="H3" className="mb-2">
            {AccommodationLinks.Zimmer[state?.lang as lang]}
          </Text>

          {page?.roomText?.[state?.lang as lang] && (
            <PortableTextRenderer
              body={page?.roomText?.[state?.lang as lang]}
            />
          )}

          <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
            {page.accommodationOptions?.map((item, i: number) => (
              <Card
                key={i}
                uniqueRef={item?.["_id"]!}
                _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
                imageAlt={""}
                imageUrl={buildImageFocalPoint(
                  item?.accommodationOptionImage?.image
                )}
                title={(item?.title && item?.title[state?.lang as lang]) || ""}
                ctaText={
                  buttonTransaltion.WEITERLESEN[state?.lang as lang] || ""
                }
                cardType="REGION"
                bodyTextPropsAlt={item?.subTitle?.[state?.lang as lang] && item}
                generateBodyTextFxn={renderPortableText}
                showCTA={false}
                ctaUrl={""}
                cardSize={"SMALL"}
              />
            ))}
          </div>
        </div>

        <div
          id={AccommodationLinks.Aktivitaten[state?.lang as lang]}
          className="my-10"
        >
          <Text type="H3" className="text-payne-gray mb-4">
            {AccommodationLinks.Aktivitaten[state?.lang as lang]}
          </Text>

          {page?.activitiesText?.[state?.lang as lang] && (
            <PortableTextRenderer
              body={page?.activitiesText?.[state?.lang as lang]}
            />
          )}
        </div>

        <section
          id={AccommodationLinks.Preiskategorie[state?.lang as lang]}
          className="my-10"
        >
          <Text type="H3" className=" text-payne-gray">
            {AccommodationLinks.Preiskategorie[state?.lang as lang]}
          </Text>
          <Text type="P" ptype="SM_LIGHT" className="text-granite-green">
            {accommodation["Explain Euro sign"][state?.lang as lang]}
          </Text>
          <div className="my-3">
            {[1, 2, 3].map((_, index) => (
              <span className="inline-block mr-2" key={index}>
                <Euro
                  opacity={
                    index + 1 <=
                    (isNaN(+page?.priceRating) ? 1 : +page?.priceRating)
                      ? 1
                      : 0.5
                  }
                />
              </span>
            ))}
          </div>
        </section>
        <section className="my-10">
          <Text type="H4" className="font-normal text-granite-green text-xl">
            {accommodation.stars[state?.lang as lang]}
          </Text>
          <div className="border-b border-t py-1 inline-flex mb-5">
            {[1, 2, 3, 4, 5].map((_, index) => (
              <div className="mx-2" key={index}>
                <PawRating
                  opacity={
                    index + 1 <= (isNaN(+page?.rating) ? 1 : +page?.rating)
                      ? 1
                      : 0.5
                  }
                />
              </div>
            ))}
          </div>
          {/* <Text type="P" ptype="BASE" className="font-medium text-payne-gray text-xl">{accommodation['Our rating'][state?.lang as lang]}</Text>
          <div className="border-b border-t py-1 inline-flex">
            {[1,2,3,4,5].map((_, i) =>
              <div className="mx-2" key={i}>
                <PawRating />
              </div>
            )}
          </div> */}
          <div className="mt-10">
            <Button
              onClick={() =>
                onCartClick && onCartClick(cardCartProps as CardProps)
              }
              size="btn-base"
              color="btn-primary"
              className={`flex justify-between items-center py-0 pr-12 ${
                cardInCart ? "bg-red-600" : ""
              }`}
            >
              <div className="transform scale-75">
                <TravelBag fill="#FFFFFF" />
              </div>
              {cardInCart
                ? removeFRomTravelBag?.[state?.lang as lang]
                : addToTravelBag?.[state?.lang as lang]}
            </Button>
          </div>
        </section>
      </Wrapper>

      <PageSection
        readMoreLink={`/${state?.lang}/country/${
          pageContext?.allSlugs?.country?.[state?.lang as lang]?.current
        }/${gCPT({
          view: CurrentView.accommodation,
          lang: state?.lang as lang,
        })}#${pageHash?.Unterkunfte?.[state?.lang as lang]!}`}
        sanityContext={
          page?.parentCountry?.accomodationIntroduction &&
          page?.parentCountry?.accomodationIntroduction[state?.lang as lang]
        }
        layerBackground="bg-white-smoke"
        showReadMore={true}
        showTitle={false}
        id={`${pageHash?.Unterkunfte?.[state?.lang as lang]!} `}
        idTitle={pageID.Unterkunfte[state?.lang as lang]!}
        title={state?.countryName!}
      >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          {countryAccommodation?.map((item, i: number) => (
            <Card
              uniqueRef={item?.["_id"]!}
              key={i}
              _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
              imageAlt={
                (item?.accommodationImage?.alt &&
                  item?.accommodationImage.alt[state?.lang as lang]) ||
                ""
              }
              imageUrl={buildImageFocalPoint(item.accommodationImage?.image)}
              title={(item?.title && item?.title[state?.lang as lang]) || ""}
              ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ""}
              cardType="ACCOMODATION"
              activities={
                (item?.cardTextShort &&
                  item.cardTextShort[state?.lang as lang]) ||
                ""
              }
              price={
                `${FROM} ${
                  item?.priceCategoryFrom &&
                  formatMoney(
                    item.priceCategoryFrom,
                    item.priceCategoryCurrency
                  )
                }` || ""
              }
              showEuroIcon={false}
              ctaUrl={`/${state?.lang}/${
                item?.parentCountry?.slug &&
                item?.parentCountry?.slug[state?.lang as lang]?.current
              }/${_accomodationTranslation.slug[state?.lang as lang]}/${
                item?.slug && item?.slug[state?.lang as lang]?.current
              }`}
              location={
                (item.locationNameShort &&
                  item.locationNameShort[state?.lang as lang]) ||
                ""
              }
              cardSize={"SMALL"}
            />
          ))}
        </div>
      </PageSection>
    </Layout>
  );
};

export const query = graphql`
  query accommodationIndexPage($id: String!) {
    pageData: sanityAccommodation(_id: { eq: $id }) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle
      heroImage: _rawAccommodationImage(resolveReferences: { maxDepth: 10 })
      parentCountry {
        title: _rawTitle
        accomodationIntroduction: _rawAccommodationIntro
        slug {
          de {
            current
          }
        }
        accommodation {
          id: _id
          _id
          accommodationImage {
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            alt: _rawAlt(resolveReferences: { maxDepth: 10 })
          }
          slug {
            de {
              current
            }
          }
          cardTextShort {
            de
            _type
          }
          title {
            de
            _type
          }
          priceCategoryFrom
          priceCategoryTo
          rating
          priceCategoryCurrency
          parentRegion {
            _id
          }
          parentCountry {
            slug {
              de {
                current
                _type
              }
            }
          }
          locationNameShort {
            de
            _type
          }
          priceCategoryExpanded {
            _type
            de
          }
        }
      }
      introText: _rawIntroText(resolveReferences: { maxDepth: 10 })
      locationText: _rawLocationText(resolveReferences: { maxDepth: 10 })
      accommodationGallery: _rawAccommodationGallery(
        resolveReferences: { maxDepth: 10 }
      )
      accommodationText: _rawAccommodationText(
        resolveReferences: { maxDepth: 10 }
      )
      roomText: _rawRoomsText(resolveReferences: { maxDepth: 10 })
      accommodationOptions: _rawAccommodationOptions(
        resolveReferences: { maxDepth: 10 }
      )
      activitiesText: _rawActivitiesText(resolveReferences: { maxDepth: 10 })
      priceRating
      rating
      priceCategoryFrom
      priceCategoryCurrency
      accommodationImage {
        image: _rawImage(resolveReferences: { maxDepth: 10 })
        alt: _rawAlt(resolveReferences: { maxDepth: 10 })
      }
      cardTextShort {
        de
        _type
      }
      locationNameShort {
        de
        _type
      }
      slug {
        de {
          current
          _type
        }
      }
    }
  }
`;

export default Index;
